import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm();
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      DataToSend.append("name", data?.name);
      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("image", data?.image[0]);
      const response = await postData(
        `/masters/child-category/${id}`,
        DataToSend
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/child-category/${id}`);
    reset(response?.data);
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };
  useEffect(() => {
    GetAllCategory();
    GetEditData();
  }, []);
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal-holder"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Child Sub Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Category</Form.Label>
                    <Controller
                      name="category_id" // name of the field
                      {...register("category_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={categories}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllSubCategory(selectedOption.value);
                            setValue("category_id", selectedOption);
                            setValue("sub_category_id", null);
                          }}
                        />
                      )}
                    />
                    {errors.category_id && (
                      <span className="text-danger">
                        {errors.category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sub Category</Form.Label>
                    <Controller
                      name="sub_category_id" // name of the field
                      {...register("sub_category_id", {
                        required: "Select Sub Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.sub_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={subCategories}
                        />
                      )}
                    />
                    {errors.sub_category_id && (
                      <span className="text-danger">
                        {errors.sub_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label> Child Category</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" Child Category"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Short Description</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="short_description"
                        placeholder="Short Description"
                        className={classNames("", {
                          "is-invalid": errors?.short_description,
                        })}
                        {...register("short_description", {
                          required: "short description is required",
                        })}
                      />
                    </InputGroup>
                    {errors.short_description && (
                      <span className="text-danger">
                        {errors.short_description.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Image</Form.Label>
                    </div>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          // required: "Image is required",
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Form.Label> Image Preview</Form.Label>

                  <Col sm={9}>
                    {typeof getValues("image") == "string" ? (
                      <div className="image-preview-container">
                        <img
                          src={IMG_URL + getValues("image")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "150px", height: "130px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )
                    )}
                  </Col>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
