import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import { AddButton, EditButton, DeletButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    Select2Data,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [changeStatus, setChangeStatus] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [searchCountry, setSearchCounty] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/masters/pincode?page=${currentPage}&per_page=${perPage}&term=${search}
      &searchCountry=${searchCountry?.value || ""}&searchState=${
        searchState?.value || ""
      }&searchCity=${searchCity?.value || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    search,
    searchCountry,
    searchState,
    searchCity,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/masters/pincode/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/masters/pincode/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [showoff, setShowoff] = useState(false);

  const BulkUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await postData("/masters/pincode/bulk", formData, {});
      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        setShowoff(response.data);
      }, 1000);
      getDataAll();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const Sample = async (e) => {
    try {
      await getDownloadDataExcel("/masters/pincode/sample", {}, "Pincodes");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const GetAllCountries = async () => {
    const response = await getData("/allcountry");
    if (response?.success) {
      setCountries(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/allstate/${id}`);
    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/allcity/${id}`);
    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCountries();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Pincodes"} link={"/masters/pincode"} />
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-xxl-12 col-xl-12  ">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio ">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="add">
                          {isAllow?.includes(22) ? (
                            <Link
                              // to="/masters/state/add"
                              type="button"
                              className="btn btn-add pe-3"
                            >
                              <div onClick={() => handleShow()}>
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Pincode
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-2 mt-2">
                        <div className="add">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={colunms}
                                className="columns me-2 "
                                alt=""
                              />
                              Column Selection{" "}
                              <img src={top} className="top ms-1" alt="" />{" "}
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col1")
                                  }
                                  href="#"
                                >
                                  S. No
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col2")
                                  }
                                  href="#"
                                >
                                  Country
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col3")
                                  }
                                  href="#"
                                >
                                  State Name
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col4")
                                  }
                                  href="#"
                                >
                                  City
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col5")
                                  }
                                  href="#"
                                >
                                  Pincode
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col6")
                                  }
                                  href="#"
                                >
                                  Status
                                  {visible?.col6 ? (
                                    <FontAwesomeIcon
                                      className="ms-5"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col7")
                                  }
                                  href="#"
                                >
                                  Action
                                  {visible?.col7 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-2 mt-2">
                        {isAllow?.includes(110) ? (
                          <div className="add">
                            <button
                              className="btn btn-add pe-3"
                              onClick={() =>
                                document.getElementById("PinCodesFile").click()
                              }
                            >
                              Bulk Upload
                            </button>

                            <input
                              type="file"
                              id="PinCodesFile"
                              onChange={(e) => {
                                BulkUpload(e);
                              }}
                              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              style={{ display: "none" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-2 mt-2">
                        {isAllow?.includes(110) ? (
                          <div className="add">
                            <Link type="button" className="btn btn-add pe-3">
                              <div onClick={Sample}>Sample</div>
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-lg-12 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="  col-lg-12  col-md-12 col-12">
                          <div className="row">
                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                              <div className="sowing me-2">
                                <p className="show mt-2">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                              <div className="num me-2">
                                <Select
                                  isSearchable
                                  options={countries}
                                  value={searchCountry}
                                  placeholder="Select Country"
                                  onChange={(e) => {
                                    setSearchCounty(e);
                                    GetAllStates(e.value);
                                    setSearchState("");
                                    setSearchCity("");
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                              <div className="num me-2">
                                <Select
                                  isSearchable
                                  options={states}
                                  value={searchState}
                                  placeholder="Select State"
                                  onChange={(e) => {
                                    setSearchState(e);
                                    GetAllCities(e.value);
                                    setSearchCity("");
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                              <div className="num me-2">
                                <Select
                                  isSearchable
                                  options={city}
                                  value={searchCity}
                                  placeholder="Select City"
                                  onChange={(e) => {
                                    setSearchCity(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="Search-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSearch("");
                                    setSearchCounty("");
                                    setSearchState("");
                                    setSearchCity("");
                                    setReset(!reset);
                                  }}
                                  className="btn btn-reset w-100 "
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                            {/* </div> <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2"><div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr">S. No</th>}
                              {visible.col2 && (
                                <th className="tax-name">Country</th>
                              )}
                              {visible.col3 && (
                                <th className="tax-name">State</th>
                              )}
                              {visible.col4 && (
                                <th className="tax-name">City</th>
                              )}
                              {visible.col5 && (
                                <th className="tax-name">Pincode</th>
                              )}
                              {visible.col6 && (
                                <th className="tax-name">Status</th>
                              )}

                              {visible.col7 && (
                                <th className="active">Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && <td>{d?.country?.name}</td>}
                                  {visible.col3 && <td>{d?.state?.name}</td>}
                                  {visible.col4 && <td>{d?.city?.name}</td>}
                                  {visible.col5 && <td>{d?.name}</td>}
                                  {visible.col6 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          disabled={!isAllow?.includes(23)}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}

                                  {visible.col7 && (
                                    <td>
                                      <div className="d-flex">
                                        {isAllow?.includes(23) ? (
                                          <Button
                                            // to={`/masters/state/edit/${d?.id}`}
                                            onClick={() => handleShow1(d?.id)}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            <img
                                              src={pen}
                                              className="pen"
                                              alt=""
                                            />
                                          </Button>
                                        ) : (
                                          <></>
                                        )}

                                        {isAllow?.includes(24) ? (
                                          <DeletButton
                                            showDeleteRecord={showDeleteRecord}
                                            id={d?.id}
                                            name={d?.name}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />
      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />

      <Toaster position="top-right" />
      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
