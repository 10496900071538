import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";

import { Context } from "../../../utils/context";

const AddOffCanvance = (props) => {
  const id = props.show;

  const [data, setData] = useState({});
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append("stage", data?.stage?.value);
      dataToSend.append("email", data?.email);
      const response = await postData(`/vender/request/${id}`, dataToSend);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/vender/seller/${id}`);
    reset(response?.data);
    setData(response?.data);
    console.log("response?.data", response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
          className="prodcuttttt-offcanvas"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5  rounded card card">
              <Card.Body>
                <Card.Title>Seller's Request :- {data?.first_name}</Card.Title>
                <hr />
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>Name</Form.Label>

                          <InputGroup>
                            <Form.Control
                              disabled
                              type="text"
                              name="first_name"
                              placeholder="Heading"
                              className={classNames("", {
                                "is-invalid": errors?.first_name,
                              })}
                              {...register("first_name", {})}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>Contact No.</Form.Label>

                          <InputGroup>
                            <Form.Control
                              disabled
                              type="text"
                              name="contact_no"
                              placeholder="Heading"
                              className={classNames("", {
                                "is-invalid": errors?.contact_no,
                              })}
                              {...register("contact_no", {})}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>E-Mail</Form.Label>

                          <InputGroup>
                            <Form.Control
                              disabled
                              type="text"
                              name="email"
                              placeholder="Heading"
                              className={classNames("", {
                                "is-invalid": errors?.email,
                              })}
                              {...register("email", {})}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>Tax Name</Form.Label>

                          <InputGroup>
                            <Form.Control
                              disabled
                              type="text"
                              name="tax_name"
                              placeholder="Heading"
                              className={classNames("", {
                                "is-invalid": errors?.tax_name,
                              })}
                              {...register("tax_name", {})}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>Tax No.</Form.Label>

                          <InputGroup>
                            <Form.Control
                              disabled
                              type="text"
                              name="tax_no"
                              placeholder="Heading"
                              className={classNames("", {
                                "is-invalid": errors?.tax_no,
                              })}
                              {...register("tax_no", {})}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>PAN No.</Form.Label>

                          <InputGroup>
                            <Form.Control
                              disabled
                              type="text"
                              name="pan_no"
                              placeholder="Heading"
                              className={classNames("", {
                                "is-invalid": errors?.pan_no,
                              })}
                              {...register("pan_no", {})}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    {/* <Col md={4}>
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Commission</Form.Label>

                        <InputGroup>
                          <Form.Control
                            disabled
                            type="text"
                            name="commission"
                            placeholder="Heading"
                            className={classNames("", {
                              "is-invalid": errors?.commission,
                            })}
                            {...register("commission", {
                            
                            })}
                          />
                        </InputGroup>

                      </Form.Group>
                    </div>

                  </Col> */}

                    <Row
                      lg={12}
                      style={{ marginLeft: "300px", marginTop: "50px" }}
                    >
                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>LOGO</Form.Label>
                            <Col sm={9}>
                              <div className="image-preview-container">
                                <img
                                  src={IMG_URL + getValues("logo")}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "150px", height: "130px" }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Address Proof</Form.Label>
                            <Col sm={9}>
                              <div className="image-preview-container">
                                <img
                                  src={IMG_URL + getValues("address_proof")}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "150px", height: "130px" }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>ID Card</Form.Label>
                            <Col sm={3}>
                              <div className="image-preview-container">
                                <img
                                  src={IMG_URL + getValues("n_identity_card")}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "250px", height: "150px" }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Form.Group>
                          <Form.Label>Take a action</Form.Label>

                          <InputGroup>
                            <Controller
                              name="stage" // name of the field
                              {...register("stage", {
                                required: "Take an Action",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.stage
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={[
                                    { value: "Approved", label: "Approve" },
                                    { value: "Rejected", label: "Reject" },
                                  ]}
                                />
                              )}
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>

                    {watch("stage") === "Approved" && (
                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Form.Group>
                            <Form.Label>Commission</Form.Label>
                            <InputGroup>
                              <Form.Control
                                disabled
                                type="text"
                                name="commission"
                                placeholder="Commission"
                                className={classNames("", {
                                  "is-invalid": errors?.commission,
                                })}
                                {...register("commission", {
                                  required: "Commission is required",
                                })}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </Col>
                    )}

                    <Row className="mt-5 pb-3">
                      <div className="d-flex justify-content-center">
                        <Link>
                          <CancelButton
                            name={"Back"}
                            handleClose={props.handleClose}
                          />
                        </Link>

                        <SaveButton
                          name={"save"}
                          handleSubmit={handleSubmit(onSubmit)}
                        />
                      </div>
                    </Row>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
