import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import eye from "../../../Components/assets/icons/eye.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    IMG_URL,
    postData,
    Select2Data,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(0);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchCountry, setSearchCountry] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchPincode, setSearchPincode] = useState("");
  const [changeStatus, setChangeStatus] = useState();
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [pincode, setPincode] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");
    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/allstate/${id}`);
    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/allcity/${id}`);
    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  const GetAllPincodes = async (id) => {
    const response = await getData(`/allpincodes/${id}`);
    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  const getDataAll = async () => {
    const response = await getData(
      `/employee/seller?page=${currentPage}&per_page=${perPage}&term=${search}` +
        `&searchCountry=${searchCountry?.value || ""}&searchState=${
          searchState?.value || ""
        }&searchCity=${searchCity?.value || ""}&searchPincode=${
          searchPincode?.value || ""
        }`
    );
    console.log(response?.data);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    // setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    search,
    searchCountry,
    searchState,
    searchCity,
    searchPincode,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/vender/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(0);
  const handleShow = (id) => {
    setShowAdd(id);
  };

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/employee/seller/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
    col14: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Vendor's Detail"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                      {/* <div className="add">
                        {isAllow.includes(6) ? (
                          <Link
                            // to="/employee/employee_details/add"
                            type="button"
                            className="btn btn-add pe-3"
                          >
                            <div onClick={() => handleShow()}>
                              <img src={plus} className="plus me-2 ms-0" alt="" />
                              Add Employee
                            </div>
                          </Link>
                        ) : (
                          <></>
                        )}
                      </div> */}

                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                S. No
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                First Name
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Last Name{" "}
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Contact Number
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Alt Contact Number
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Email
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Address
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col8")}
                                href="#"
                              >
                                Country
                                {visible?.col8 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col9")}
                                href="#"
                              >
                                State
                                {visible?.col9 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>{" "}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col10")
                                }
                                href="#"
                              >
                                City
                                {visible?.col10 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col11")
                                }
                                href="#"
                              >
                                Pincode
                                {visible?.col11 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col12")
                                }
                                href="#"
                              >
                                Commission
                                {visible?.col12 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col13")
                                }
                                href="#"
                              >
                                Status
                                {visible?.col13 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col14")
                                }
                                href="#"
                              >
                                Action
                                {visible?.col14 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="  col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12  col-md-12 col-12">
                        <div className="row">
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                          </div>

                          <div className="col-xxl-2 col-xl-2  col-lg-3  col-md-6 col-12 mt-md-0 mt-3">
                            <div className="num me-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name/email/Address/Contact No"
                                id=""
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="num me-2">
                              <Select
                                isSearchable
                                options={country}
                                value={searchCountry}
                                placeholder="Select Country"
                                onChange={(e) => {
                                  setSearchCountry(e);
                                  GetAllStates(e.value);
                                  setSearchState("");
                                  setSearchCity("");
                                  setSearchPincode("");
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                            <div className="num me-2">
                              <Select
                                isSearchable
                                options={states}
                                value={searchState}
                                placeholder="Select State"
                                onChange={(e) => {
                                  setSearchState(e);
                                  GetAllCities(e.value);
                                  setSearchCity("");
                                  setSearchPincode("");
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                            <div className="num me-2">
                              <Select
                                isSearchable
                                options={city}
                                value={searchCity}
                                placeholder="Select City"
                                onChange={(e) => {
                                  GetAllPincodes(e?.value);
                                  setSearchCity(e);
                                  setSearchPincode("");
                                }}
                              />
                            </div>{" "}
                          </div>

                          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ">
                            <div className="num me-2">
                              <Select
                                isSearchable
                                options={pincode}
                                value={searchPincode}
                                placeholder="Select Pincode"
                                onChange={(e) => {
                                  setSearchPincode(e);
                                }}
                              />
                            </div>{" "}
                          </div>

                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearch("");
                                  setSearchCountry("");
                                  setSearchState("");
                                  setSearchCity("");
                                  setSearchPincode("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset w-100"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                          {/* </div> <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2"><div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">S. No</th>}
                            {visible.col2 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                First Name
                              </th>
                            )}
                            {visible.col3 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Last Name
                              </th>
                            )}
                            {visible.col4 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Contact Number
                              </th>
                            )}{" "}
                            {visible.col5 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Alt Contact Number
                              </th>
                            )}
                            {visible.col6 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Employee Email
                              </th>
                            )}
                            {visible.col7 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Address
                              </th>
                            )}
                            {visible.col8 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Country
                              </th>
                            )}{" "}
                            {visible.col9 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                State
                              </th>
                            )}
                            {visible.col10 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                City
                              </th>
                            )}{" "}
                            {visible.col11 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Pincode
                              </th>
                            )}
                            {visible.col12 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Commission %
                              </th>
                            )}
                            {/* {visible.col6 && (
                            <th className="tax-name">Password</th>
                          )} */}
                            {visible.col13 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Status
                              </th>
                            )}
                            {visible.col14 && (
                              <th
                                className="tax-name"
                                style={{ width: "900px" }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col2 && <td>{d?.first_name}</td>}
                                {visible.col3 && <td>{d?.last_name}</td>}
                                {visible.col4 && <td>{d?.contact_no}</td>}{" "}
                                {visible.col5 && <td>{d?.alt_contact_no}</td>}
                                {visible.col6 && <td>{d?.email}</td>}
                                {visible.col7 && <td>{d?.address}</td>}
                                {visible.col8 && <td>{d?.country?.name}</td>}
                                {visible.col9 && <td>{d?.state?.name}</td>}
                                {visible.col10 && <td>{d?.city?.name}</td>}
                                {visible.col11 && <td>{d?.pincode?.name}</td>}
                                {visible.col12 && (
                                  <td>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span>{d?.commission}%</span>
                                      <Button
                                        onClick={() => handleShow1(d?.id)}
                                        type="button"
                                        className="btn btn-primary"
                                      >
                                        <img src={pen} className="pen" alt="" />
                                      </Button>
                                    </div>
                                  </td>
                                )}
                                {visible.col13 && (
                                  <td>
                                    {d?.id === 1 ? (
                                      <></>
                                    ) : (
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    )}
                                  </td>
                                )}
                                {visible.col14 && (
                                  <td>
                                    {d?.id === 1 ? (
                                      <></>
                                    ) : (
                                      <div className="d-flex">
                                        {/* {isAllow.includes(8) ? ( */}
                                        {/* <button
                                          onClick={() => {
                                            showDeleteRecord(
                                              d?.id,
                                              d?.first_name
                                            );
                                          }}
                                          type="button"
                                          className="btn btn-danger"
                                        >
                                          <img
                                            src={basket}
                                            className="pen"
                                            alt=""
                                          />
                                        </button> */}

                                        {/* ) : (
                                        <></>
                                      )} */}

                                        {/* <Link to={`/product/${d?.id}`}> Products</Link> */}

                                        {/* View Products Link */}
                                        <Link
                                          to={`/studio/${d?.id}`}
                                          className="me-5"
                                        >
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="me-2"
                                          />{" "}
                                          View Studio
                                        </Link>

                                        {/* Edit Button */}
                                        {/* <Button
                                          onClick={() => handleShow(d?.id)}
                                          type="button"
                                          className="btn btn-primary" // Add margin to the right
                                        >
                                          <img
                                            src={eye}
                                            className="pen"
                                            alt=""
                                          />
                                        </Button> */}
                                      </div>
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
