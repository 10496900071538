import React from "react";
import Lottie from "react-lottie";
import * as animationData from "./Animation/LoginLottie.json";
import "../../Login/LoginPage/Animation/LoginLottie.css";

const LoginLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <section className="main-lottey">
        <div className="success mx-auto ">
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "/Mansa_Nutri_Logo.png"}
              className="logoimgg"
            />
          </div>
        </div>
      </section>
      {/* <section className="main-lottey">
        <div className="success mx-auto">
          <Lottie options={defaultOptions} />
        </div>
      </section> */}
    </>
  );
};

export default LoginLottie;
