import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [role, setRole] = useState([]);
  const [formData, setFormData] = useState({
    role_id: "",
    first_name: "",
    last_name: "",
    contact_no: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "role_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".employeedetailclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".employeedetailclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/employee/customers", formData);
        if (response?.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const GetAllRoles = async () => {
    const response = await getData(
      "/common/role-and-permission/masters/all-roles"
    );

    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "role_id",
          label: data.name,
        });
      });
    }
    setRole(option);
  };

  useEffect(() => {
    GetAllRoles();
  }, []);

  // Contact Validation
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
          className="prodcuttttt-offcanvas"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5  rounded card card">
              <Card.Body>
                <Card.Title>Add Employee Details</Card.Title>
                <hr />
                <Container>
                  <Row>
                    <Col md={12}>
                      <Container>
                        <Row className="">
                          <Col xxl={12} xl={12} md={10}>
                            <Form
                              onSubmit={handleSubmit}
                              role="form"
                              className="employeedetailclass"
                            >
                              <Row>
                                {/* <Col
                                  lg={8}
                                  md={10}
                                  className=" mx-auto Add-content"
                                >
                                  <Row> */}
                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="row justify-content-center mb-2">
                                      <Form.Label column sm={3}>
                                        Employee Role{" "}
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Select
                                          name="role_id"
                                          className="input-mandatory"
                                          onChange={handleChange}
                                          value={formData.role_id}
                                          options={role}
                                        />

                                        <span className="error-message"></span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        First Name
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="first_name"
                                              value={formData.first_name}
                                              onChange={handleChange}
                                              placeholder="Employee First Name"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Last Name
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="last_name"
                                              value={formData.last_name}
                                              onChange={handleChange}
                                              placeholder="Employee Last Name"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Employee Mobile
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="tel"
                                              name="contact_no"
                                              value={formData.contact_no}
                                              onChange={handleChange}
                                              placeholder="Contact Number"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                              onKeyPress={handleKeyPressContact}
                                              maxLength={10}
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Employee Email
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="email"
                                              name="email"
                                              value={formData.email}
                                              onChange={handleChange}
                                              placeholder="Employee Email"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Employee Password
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="password"
                                              value={formData.password}
                                              onChange={handleChange}
                                              placeholder="Employee Password"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Conform Password
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="cpassword"
                                              value={formData.cpassword}
                                              onChange={handleChange}
                                              placeholder="Re-enter password"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                {/* </Row> */}

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                                {/* </Col> */}
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
