import React, { useState, useContext } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./SettingContent.css";
import { Context } from "../../../utils/context";

import Citytable from "../City/Tables";
import Statetable from "../State/Tables";
import Countrytable from "../Country/Tables";
import Pincodetable from "../Pincode/Tables";
import CountryCode from "../CountryCode/Tables";
import CategoryTable from "../Category/Tables";
import SubCategoryTable from "../Sub_Category/Tables";
import ChildSubCategoryTable from "../Child_Sub_Category/Tables";
import FaqCategory from "../FaqCategory/Tables";
import Faqs from "../FAQs/Tables";

import Banner from "../S_Banner/Tables";
import WebHeader from "../WebHeader/Tables";
import AppSetup from "../AppSetup/Tables";

const SettingContent = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const { isAllow } = useContext(Context);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabItems = [
    { key: 1, name: "Country", link: "masters/country" },
    { key: 2, name: "State", link: "masters/state" },
    { key: 3, name: "City", link: "masters/city" },
    { key: 4, name: "Pincode", link: "masters/pincode" },
    { key: 5, name: "Country Code", link: "masters/country-code" },
    { key: 6, name: "Category", link: "masters/category" },
    { key: 7, name: "Sub Category", link: "masters/sub-category" },
    { key: 8, name: "Child Sub category", link: "masters/child-sub-category" },
    { key: 9, name: "FAQ Category", link: "masters/faq-category" },
    { key: 10, name: "FAQs", link: "masters/faqs" },
    { key: 11, name: "Brands", link: "masters/brand" },

    { key: 14, name: "Coupon Codes", link: "masters/cuppon-codes" },

    { key: 16, name: "Banner", link: "masters/banner" },
    { key: 17, name: "Web Header", link: "masters/web-header" },
    { key: 18, name: "App Setup", link: "masters/app-setup" },
    { key: 19, name: "Attribute", link: "masters/attribute" },
    { key: 20, name: "Sub Attribute", link: "masters/sub-attribute" },
  ];

  return (
    <>
      <Header title={tabItems[active]?.name} link={"/masters/country"} />

      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row>
          <Col xl={2} lg={3} md={4}>
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={9} md={8}>
            <Tab.Content className="tabadminmain">
              {isAllow?.includes(9) ? (
                <Tab.Pane eventKey="1">
                  <Countrytable />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(13) ? (
                <Tab.Pane eventKey="2">
                  <Statetable />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(17) ? (
                <Tab.Pane eventKey="3">
                  <Citytable />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(21) ? (
                <Tab.Pane eventKey="4">
                  {" "}
                  <Pincodetable />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(25) ? (
                <Tab.Pane eventKey="5">
                  {" "}
                  <CountryCode />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(29) ? (
                <Tab.Pane eventKey="6">
                  {" "}
                  <CategoryTable />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(33) ? (
                <Tab.Pane eventKey="7">
                  {" "}
                  <SubCategoryTable />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(37) ? (
                <Tab.Pane eventKey="8">
                  {" "}
                  <ChildSubCategoryTable />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(41) ? (
                <Tab.Pane eventKey="9">
                  {" "}
                  <FaqCategory />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(45) ? (
                <Tab.Pane eventKey="10">
                  {" "}
                  <Faqs />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}

              {isAllow?.includes(69) ? (
                <Tab.Pane eventKey="16">
                  {" "}
                  <Banner />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(73) ? (
                <Tab.Pane eventKey="17">
                  {" "}
                  <WebHeader />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
              {isAllow?.includes(75) ? (
                <Tab.Pane eventKey="18">
                  {" "}
                  <AppSetup />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SettingContent;
